.hflink,
.hflink:visited,
.hflink:hover {
  color: white;
  padding: 0 4px;
}

.hflink:not(:hover) {
  text-decoration: none;
}

.hfConfigurable {
  display: flex;
  height: 40px;
  align-items: center;
}
